import { Message } from 'ai'
import { useSession } from 'next-auth/react'
import { Fragment, useEffect, useRef, useState } from 'react'

import { MessageAnnotation, MessageAnnotationType, SourceData, getAnnotationData } from '../ui/chat'
import ChatFollowUpQuestions from '../ui/chat/chat-follow-up-questions'
import { ChatTabSources } from '../ui/chat/chat-tab-sources'
import ChatAvatar from './ChatAvatar'
import Markdown from './Markdown'

type ContentDisplayConfig = {
  order: number
  component: JSX.Element | null
}
const extractAndRemoveJSONFromStream = (
  content: string
): { cleanedContent: string; followupQuestions: string[] | null } => {
  try {
    // Find the JSON object inside stream using regex
    const jsonMatch = content.match(/\{[\s\S]*?\}$/)
    if (!jsonMatch) return { cleanedContent: content, followupQuestions: null }

    const parsedData = JSON.parse(jsonMatch[0])

    const cleanedContent = content.replace(jsonMatch[0], '').trim()

    return { cleanedContent, followupQuestions: parsedData.followup_questions || null }
  } catch (error) {
    console.error('Error extracting and removing JSON:', error)
    return { cleanedContent: content, followupQuestions: null }
  }
}

const ChatMessageContent = ({ message, isLoading }: { message: Message; isLoading: boolean }) => {
  const [messageContentWithoutFollowUps, setMessageContentWithoutFollowUps] = useState<string>(message.content)
  const [followupQuestions, setFollowupQuestions] = useState<string[] | null>(null)

  useEffect(() => {
    const { cleanedContent, followupQuestions } = extractAndRemoveJSONFromStream(message.content)
    setMessageContentWithoutFollowUps(cleanedContent)
    setFollowupQuestions(followupQuestions)
  }, [message.content])

  const [isProd, setIsProd] = useState<boolean>(true)
  const { data: session } = useSession()
  const messageRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setIsProd(!(session as any)?.user?.isCGN)
  }, [session])
  useEffect(() => {
    if (isLoading) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isLoading])
  const annotations = message.annotations as MessageAnnotation[] | undefined
  if (!annotations?.length) return <Markdown content={message.content} />
  const sourceData = getAnnotationData<SourceData>(annotations, MessageAnnotationType.SOURCES)
  const contents: ContentDisplayConfig[] = [
    {
      order: 0,
      component: <Markdown content={messageContentWithoutFollowUps || message.content} />,
    },
    {
      order: 1,
      component: sourceData[0] ? <ChatTabSources data={sourceData[0]} /> : null,
    },
    {
      order: 2,
      component: followupQuestions ? <ChatFollowUpQuestions followUpQuestions={followupQuestions} /> : null,
    },
  ]
  return (
    <div ref={messageRef} className="flex-1 gap-4 flex flex-col">
      {contents
        .sort((a, b) => a.order - b.order)
        .map((content, index) => (
          <Fragment key={index}>{content.component}</Fragment>
        ))}
    </div>
  )
}
const ChatMessage = ({ chatMessage, isLoading }: { chatMessage: Message; isLoading: boolean }) => {
  return (
    <div className={`flex items-start gap-4 pr-5 w-full pt-2`}>
      <ChatAvatar role={chatMessage.role} />
      <div
        className={`group flex flex-1 justify-between gap-2 ${chatMessage.role === 'assistant' ? 'pt-0' : 'pt-3'}  break-keep`}
      >
        <ChatMessageContent message={chatMessage} isLoading={isLoading} />
      </div>
    </div>
  )
}
export default ChatMessage
