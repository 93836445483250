'use client'

import { useTranslations } from 'next-intl'

import { useAvaSidebar } from '@/providers/AvaSidebarProvider'
import { useChatbot } from '@/providers/ChatbotProvider'

const ChatFollowUpQuestions = ({ followUpQuestions }: { followUpQuestions: string[] }) => {
  const { setInputValue, setInitialPrompt } = useChatbot()
  const { toggleSidebar } = useAvaSidebar()

  const handleQuestionClick = (question: string) => {
    setInputValue(question)
    setInitialPrompt(question)
    toggleSidebar(false)
  }
  const t = useTranslations('AskAva')

  if (!followUpQuestions || followUpQuestions.length === 0) {
    return null
  }
  return (
    <div className="flex flex-col gap-y-2">
      <p className="font-bold">{t('suggestedFollowUpQuestions')}</p>
      <div className="flex flex-col gap-2 w-fit items-start">
        {followUpQuestions.map((question, index) => (
          <button
            key={index}
            className="py-1 px-2 bg-white rounded-2xl cursor-pointer hover:bg-gray-300 text-left"
            onClick={() => handleQuestionClick(question)}
          >
            {question}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ChatFollowUpQuestions
