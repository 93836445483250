'use client'

import { Button, Option, Select, Spinner } from '@material-tailwind/react'
import { User } from 'next-auth'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import LanguageSwitcherDropdown from '../LanguageSwitcherDropdown'

interface SetupFormInputs {
  studentName?: string
  graduationYear: string
  pathways: string[]
}

interface PathwaysInputs {
  pathways: string[]
}

interface Props {
  availablePathways: { [key: string]: string[] }
  updateState: any
}

const EditProfile = (props: Props) => {
  const { availablePathways } = props
  const t = useTranslations('EditProfile')
  const tP = useTranslations('Pathways')
  const tPC = useTranslations('PathwayCategories')
  const { data: session, update } = useSession()
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [graduationYear, setGraduationYear] = useState<string | undefined>(session?.user.graduationYear)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<SetupFormInputs>({
    defaultValues: {
      studentName: session?.user.studentName,
      graduationYear: session?.user.graduationYear,
      pathways: session?.user.pathways || [],
    },
  })

  const onSubmit = async (data: PathwaysInputs): Promise<void> => {
    setShowSuccess(false)
    setError(null)
    const response = await fetch('/api/profile/pathways', {
      method: 'POST',
      body: JSON.stringify({ pathways: data.pathways }),
    })
    if (response.status === 204) {
      const updateObj: Partial<User> = {
        graduationYear: graduationYear,
        pathways: data.pathways,
      }
      await update(updateObj)
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 5000)
    } else {
      try {
        const body = await response.json()
        setError(body.error || 'Unknown error')
      } catch (_err) {
        setError('Unknown error')
      }
    }
  }
  //use same approach as onboarding form
  const currentYear = new Date().getFullYear()
  const monthModifier = new Date().getMonth() < 6 ? 0 : 1

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col md:flex-row mb-2">
        <span className="text-base font-bold text-black">{t('language')}:</span>
        <LanguageSwitcherDropdown />
      </div>
      {session?.user.role !== UserRoles.COUNSELOR && (
        <div className="flex flex-col md:flex-row mb-2">
          <p className="text-base font-bold text-black lg:whitespace-nowrap">{t('graduationYear')}:</p>
          <div className="flex w-72 lg:w-60 xl:w-72 md:ml-16">
            <Select
              label={t('selectGraduationYear')}
              value={graduationYear?.toString()}
              {...register('graduationYear')}
              onChange={(value) => setGraduationYear(value)}
            >
              {new Array(16).fill(null).map((_, i) => (
                <Option value={(currentYear + i + monthModifier).toString()} key={i}>
                  {currentYear + i + monthModifier}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
      {Object.keys(availablePathways)
        .filter((category) => category !== 'Types of Schools') // Exclude 'Types of Schools' (COL-386)
        .map((category) => (
          <div className="grid grid-row md:grid-cols-[225px_minmax(200px,_1fr)_0px] gap-1 mb-3" key={category}>
            <p className="text-base font-bold text-black">{tPC(category)}: </p>
            <div className="flex flex-col ml-6 md:-ml-10 gap-2">
              {availablePathways[category].map((pathway) => (
                <div className="flex items-start gap-1" key={pathway}>
                  <input
                    type="checkbox"
                    className="text-black focus:black mt-[0.32rem] rounded border-gray-300 bg-gray-50 transition"
                    value={pathway}
                    {...register('pathways')}
                  />
                  <p className="text-base text-black">{tP(pathway)}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      <div className="flex flex-row text-center gap-7 justify-end my-5 right-2">
        <Button
          className="cursor-pointer bg-white text-black text-sm font-sans"
          onClick={() => props.updateState(false)}
        >
          {t('cancel')}
        </Button>
        <Button type="submit" className="bg-brand-purple text-sm font-sans" disabled={isSubmitting}>
          {isSubmitting ? <Spinner className="mx-auto h-4 w-4 text-white" /> : <span>{t('save')}</span>}
        </Button>
      </div>
    </form>
  )
}

export default EditProfile
