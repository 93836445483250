'use client'

import { Button } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import PreferenceContainer from './PreferenceContainer'

interface Inputs {
  zipCode: string
}

interface Props {
  updateState?: any
}

const ZipCodePrefPanel = (props: Props) => {
  const t = useTranslations('Preferences')
  const { data: session, update } = useSession()
  const [saved, setSaved] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<Inputs>({ defaultValues: { zipCode: session?.user.zipCode } })

  const handleZipCodeSubmit = async (data: Inputs) => {
    await update({
      zipCode: data.zipCode,
    })
    setSaved(true)
    setTimeout(() => setSaved(false), 3000)

    // Reset the form and update default zipCode
    reset({ zipCode: data.zipCode })
  }

  const handleFocus = (inputElement: HTMLInputElement) => {
    setTimeout(() => {
      inputElement.focus()
    }, 100)
  }

  return (
    <PreferenceContainer title={t('zipCode')} roles={[UserRoles.FAMILY, UserRoles.STUDENT, UserRoles.COUNSELOR]}>
      <form className="mt-4" onSubmit={handleSubmit(handleZipCodeSubmit)}>
        <div>
          <input
            id="zipCode"
            type="text"
            placeholder="00000"
            {...register('zipCode', {
              required: true,
              pattern: {
                value: /^[0-9]{5}$/,
                message: '',
              },
            })}
            style={{
              borderRadius: '0.75rem',
              border: '1px solid rgb(224 224 224 / var(--tw-border-opacity))',
              padding: '0.5rem',
            }}
            onFocus={(e) => handleFocus(e.target as HTMLInputElement)}
            onInput={(e) => {
              // Sanitize input to allow only numbers
              const input = e.target as HTMLInputElement
              input.value = input.value.replace(/[^0-9]/g, '')
            }}
          />
        </div>

        <div className="block my-4 gap-y-2">
          <Button
            className="cursor-pointer bg-white text-black text-sm font-sans mr-4"
            onClick={() => props.updateState(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            className="bg-brand-purple"
            variant="filled"
            size="lg"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {t('save')}
          </Button>
          {saved && <p className="mt-1 text-sm font-semibold text-green-600">{t('saved')}</p>}
        </div>
      </form>
    </PreferenceContainer>
  )
}

export default ZipCodePrefPanel
