'use client'

import { LightBulbIcon } from '@heroicons/react/24/outline'
import { Spinner } from '@material-tailwind/react'
import { useTranslations } from 'next-intl'
import { useLocale } from 'next-intl'
import Image from 'next/image'
import { useEffect, useState } from 'react'

import avaIcon from '@/public/images/ava-icon.png'

import { toI18nString } from '@roadmap/lib/i18n'
import { Locales, StarterQuestionRecord } from '@roadmap/lib/types'

interface Card {
  text: string
  onClick: () => void
}

interface AvaContentProps {
  onCardSelected: (cardContent: string) => void
  isOpen: boolean
  generationComplete: any
  setGenerationComplete: any
  generatedCards: StarterQuestionRecord[]
}

const PromptCard = (card: Card) => (
  <div
    onClick={card.onClick}
    className="flex sm:flex-col items-center p-2.5 sm:p-4 border rounded-2xl shadow-lg bg-white cursor-pointer"
  >
    <div className="w-8 h-8 sm:mb-2 mr-2 sm:mr-0">
      <LightBulbIcon className="h-7 w-7" />
    </div>
    <p>{toI18nString(card.text)}</p>
  </div>
)

const AvaInitialState = ({
  onCardSelected,
  isOpen,
  generationComplete,
  setGenerationComplete,
  generatedCards,
}: AvaContentProps) => {
  const t = useTranslations('AskAva')
  const avaIntro = t('avaIntro')
  const suggestedPromtsIntro = t('suggestedPromtsIntro')

  const [introText, setIntroText] = useState('')
  const [index, setIndex] = useState(0)
  const [cardsDisplayed, setCardsDisplayed] = useState(false)
  const [cardsToDisplay, setCardsToDisplay] = useState<string[]>([])
  const [suggestedPromtsText, setSuggestedPromtsText] = useState('')
  const [suggestedIndex, setSuggestedIndex] = useState(0)

  const speed = 5
  const suggestedPromtsSpeed = 5
  const cardDisplaySpeed = 500
  const locale = useLocale() as Locales

  const handleCardClick = (text: string) => {
    onCardSelected(text)
  }

  useEffect(() => {
    if (generationComplete) {
      setIntroText(avaIntro)
      return
    }
    if (isOpen && index < avaIntro.length) {
      const timeout = setTimeout(() => {
        setIntroText(introText + avaIntro.charAt(index))
        setIndex(index + 1)
      }, speed)

      return () => clearTimeout(timeout)
    } else if (index >= avaIntro.length && !cardsDisplayed) {
      setCardsDisplayed(true)
    }
  }, [index, isOpen, introText, cardsDisplayed, generationComplete])

  useEffect(() => {
    if (generationComplete) {
      setSuggestedPromtsText(suggestedPromtsIntro)
      return
    }

    if (cardsDisplayed && suggestedIndex < suggestedPromtsIntro.length) {
      const timeout = setTimeout(() => {
        setSuggestedPromtsText(suggestedPromtsText + suggestedPromtsIntro.charAt(suggestedIndex))
        setSuggestedIndex(suggestedIndex + 1)
      }, suggestedPromtsSpeed)

      return () => clearTimeout(timeout)
    }
  }, [cardsDisplayed, suggestedPromtsIntro, suggestedIndex, generationComplete])

  useEffect(() => {
    if (cardsDisplayed && cardsToDisplay.length < generatedCards.length) {
      const timeout = setTimeout(() => {
        setCardsToDisplay((prev) => [...prev, toI18nString(generatedCards[cardsToDisplay.length].question, locale)])
        if (cardsToDisplay.length + 1 == generatedCards.length) {
          setGenerationComplete(true)
        }
      }, cardDisplaySpeed)

      return () => clearTimeout(timeout)
    }
  }, [cardsDisplayed, cardsToDisplay, generationComplete, generatedCards])

  useEffect(() => {
    if (generationComplete) {
      setCardsToDisplay(generatedCards.map((card) => toI18nString(card.question, locale)))
      return
    }
  }, [])

  return (
    <div id="ava-sidebar-content" className="p-2 md:p-4">
      <div className="flex items-center space-x-2">
        <div className="relative w-[28px] h-[45px]">
          <Image
            src={avaIcon}
            alt="Ava Icon"
            fill
            style={{ objectFit: 'contain' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vwvw, 100vw"
          />
        </div>
        <h1 className="text-xl font-bold text-gray-900">{t('askAva')}</h1>
      </div>

      <div className="text-gray-900 mt-4 md:mt-8">{introText}</div>

      {cardsDisplayed && (
        <div className="text-gray-900 mt-3 md:mt-6">
          <p>{suggestedPromtsText}</p>
        </div>
      )}

      {generatedCards.length == 0 && (
        <div className="flex mt-10 items-center justify-center  w-full">
          <Spinner />
        </div>
      )}

      {/* Prompt cards */}
      {generationComplete || cardsToDisplay.length > 0 ? (
        <div className="text-gray-900">
          <div className="container mx-auto p-2 sm:p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {cardsToDisplay.map((card, index) => (
                <PromptCard key={index} text={card} onClick={() => handleCardClick(card)} />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AvaInitialState
